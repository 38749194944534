import { useRuntimeConfig } from '#app';

/**
 * used to navigate to a specific path on different subdomains (hive.pb.example and have-trust.pb.example)
 * @param path
 * @param toTrust
 */
export function usePbRouter(path: string, toTrust: boolean = false) {
    const config = useRuntimeConfig();
    const router = useRouter();
    const url = useRequestURL();

    const hostname = url.hostname || location.hostname;

    //check if current host is trust pages
    const fromTrust = config.public.pb.trustHost.includes(hostname);

    if (toTrust) {
        //route without refreshing page if from trust to trust
        if (fromTrust) {
            return router.push(path);
        }

        return (window.location.href = config.public.pb.trustHost + path);
    }

    //use regular router if we want to go to portal from portal
    if (!fromTrust) {
        return router.push(path);
    }

    return (window.location.href = config.public.pb.appDomain + path);
}
