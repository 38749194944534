export default {
    root: ({ props, context, parent }) => ({
        class: [
            // Font
            'inputtext-root',

            { 'w-full': props.fluid },

            { 'bg-white-700': !context.disabled },

            { 'border-slate-300': !props.invalid },

            { 'inputtext-root_invalid': props.invalid },

            {
                'inputtext-root_disabled': context.disabled,
            },
        ],
    }),
};
