import type { Person } from '~/types/personal/person';
import * as Sentry from '@sentry/vue';

export const usePersonalStore = defineStore('personal', () => {
    const person = ref(null as Person | null);
    const auth = useAuthStore();
    const nuxtApp = useNuxtApp();

    async function getPerson() {
        if (!auth.user) return;
        if (person.value) return;

        const { data, error } = await usePbFetch(nuxtApp.$api.v3.people.person(auth.user.id));

        if (error.value) {
            Sentry.captureException({ personal_getperson_error: error.value });
        }

        person.value = data.value.data;
    }

    return { person, getPerson };
});
