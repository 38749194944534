export default {
	root: '',
	thead: {
		class: ['datatable-thead'],
	},
	headerrow: {
		class: ['datatable-header-row'],
	},
	bodyrow: {
		class: ['datatable-body-row'],
	},
};