export default {
    root: {
        class: ['checkbox-root'],
    },
    box: ({ props, context }) => ({
        class: [
            // Alignment
            'checkbox-box',

            //disabled
            { checkbox_disabled: props.disabled },

            // Colors
            {
                'border-blue-700': !context.checked && !props.invalid,
                'checbox-box_checked': context.checked && !props.invalid,
            },

            // Invalid State
            'invalid:focus:ring-red-200',
            'invalid:hover:border-red-700',
            { 'checkbox-box_invalid': props.invalid },
        ],
    }),
    input: {
        class: ['checkbox-input', 'border border-surface-300 dark:border-surface-700'],
    },
    icon: ({ context, state }) => ({
        class: [
            // Size
            'w-[0.875rem]',
            'h-[0.875rem]',

            // Colors
            {
                'text-white dark:text-surface-950': context.checked,
                'text-primary': state.d_indeterminate,
            },

            // Transitions
            'transition-all',
            'duration-200',
        ],
    }),
};
