export default {
	root: 'select-root',
	content: {
		class: [''],
	},
	listContainer: {
		class: ['select-list-container'],
	},
	option: {
		class: ['select-option'],
	},
	label: {
		class: ['select-label'],
	},
};
