export default {
    root: ({ props }) => ({
        class: [
            'toggleswitch-root',
            {
                'toggleswith-root_disabled': props.disabled,
            },
        ],
    }),
    slider: ({ props }) => ({
        class: [
            'toggleswitch-slider',
            { 'toggle-siwtch_active': props.modelValue == props.trueValue },

            {
                'bg-slate-400': !(props.modelValue == props.trueValue),
                'bg-blue-700': props.modelValue == props.trueValue,
                'toggle-switch_inactive': props.invalid,
            },
        ],
    }),
    input: {
        class: ['toggleswitch-input'],
    },
};
