export default {
    root: 'prime-tablist',
    content: ({ instance }) => [
        'grow',
        {
            'overflow-x-auto': instance.$pcTabs.scrollable,
        },
    ],
    inkbar: 'prime-tablist-inkbar',
};
