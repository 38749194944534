export default {
    root: ({ props, context, parent, instance }) => ({
        class: [
            ['icon-field_root'],
            {
                'icon-field_root__disabled': instance.$attrs.disabled,
                'icon-field_root__invalid': instance.$attrs.invalid,
            },
        ],
    }),
};
