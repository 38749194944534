import revive_payload_client_4sVQNw7RlN from "/home/runner/work/hive-ui/hive-ui/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/hive-ui/hive-ui/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/hive-ui/hive-ui/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/hive-ui/hive-ui/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/hive-ui/hive-ui/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/hive-ui/hive-ui/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/hive-ui/hive-ui/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/hive-ui/hive-ui/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/hive-ui/hive-ui/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/hive-ui/hive-ui/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/home/runner/work/hive-ui/hive-ui/.nuxt/primevue-plugin.mjs";
import plugin_client_533RIki58s from "/home/runner/work/hive-ui/hive-ui/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
import amcharts_client_8a8YB1FKYG from "/home/runner/work/hive-ui/hive-ui/plugins/amcharts.client.ts";
import api_GFfDXud5Cr from "/home/runner/work/hive-ui/hive-ui/plugins/api.ts";
import cookie_fY1uPxNcjB from "/home/runner/work/hive-ui/hive-ui/plugins/cookie.ts";
import dynamic_dialog_yZLw7sqOQx from "/home/runner/work/hive-ui/hive-ui/plugins/dynamic-dialog.ts";
import sentry_3AyO8nEfhE from "/home/runner/work/hive-ui/hive-ui/plugins/sentry.ts";
import toast_ysMjUcU7eJ from "/home/runner/work/hive-ui/hive-ui/plugins/toast.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  primevue_plugin_egKpok8Auk,
  plugin_client_533RIki58s,
  amcharts_client_8a8YB1FKYG,
  api_GFfDXud5Cr,
  cookie_fY1uPxNcjB,
  dynamic_dialog_yZLw7sqOQx,
  sentry_3AyO8nEfhE,
  toast_ysMjUcU7eJ
]