<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

<script setup>
const route = useRoute();

useHead({
    title: route.name + ' | Privacy Bee',
});
</script>
