export default {
    root: ({ props }) => ({
        class: [
            'prime-skeleton-root',

            // Round
            { 'rounded-full': props.shape === 'circle', 'rounded-md': props.shape !== 'circle' },
        ],
    }),
};
