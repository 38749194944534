import type { UseFetchOptions } from '#app';
import { defu } from 'defu';

export function usePbFetch<T>(url: string, options: UseFetchOptions<T> = {}) {
    const auth = useAuthStore();
    const config = useRuntimeConfig();

    const defaults: UseFetchOptions<T> = {
        baseURL: config.public.pb.apiDomain,
        headers: {
            Accept: 'application/json',
            Authorization: `${auth.token}`,
        },
        onResponse(_ctx) {
            // _ctx.response._data = new myBusinessResponse(_ctx.response._data)
        },

        onResponseError(_ctx) {
            // throw new myBusinessError()
        },
    };

    // for nice deep defaults, please use unjs/defu
    const params = defu(options, defaults);

    return useFetch(url, params);
}
