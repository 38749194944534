export default defineNuxtPlugin(() => {
    const runtimeConfig = useRuntimeConfig();

    function get(key: string, maxAge = 1000) {
        return useCookie(key, { domain: runtimeConfig.public.pb.domainHost, maxAge });
    }

    return {
        provide: {
            cookie: {
                get,
            },
        },
    };
});
