export default {
    root: {
        class: 'radiobutton-root',
    },
    box: ({ props, context }) => ({
        class: [
            'radiobutton-box',

            {
                'radiobutton-box_checked': context.checked,
                'radiobutton-box_disabled': props.disabled,
            },

            // Invalid State
            { 'radiobutton-box_invalid': props.invalid, 'bg-red-700': props.invalid && context.checked },
        ],
    }),
    input: {
        class: ['radiobox-input'],
    },
};
