import { defineNuxtPlugin } from '#app';
import { useDialog } from 'primevue/usedialog';
import Ripple from 'primevue/ripple';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('ripple', Ripple);

    return {
        provide: {
            dialog: useDialog(),
        },
    };
});
