export default {
	root: 'paginator-content',
	content: {
		class: ['paginator-content'],
	},
	first: {
		class: ['paginator-page-button'],
	},
	prev: {
		class: ['paginator-page-button'],
	},
	next: {
		class: ['paginator-page-button'],
	},
	last: {
		class: ['paginator-page-button'],
	},
	current: {
		class: ['paginator-page'],
	},
	page: {
		class: ['paginator-current-page-button'],
	},
	pages: {
		class: ['paginator-pages'],
	},
};