export default {
    root: ({ context, props, parent }) => ({
        class: [
            // Font
            'textarea-input-placeholder-text textarea-root',

            { 'bg-white-700': !context.disabled },
            { 'border-slate-300': !props.invalid },

            { 'textarea-root_invalid': props.invalid },
        ],
    }),
};
