<template>
    <PrimeButton
        v-bind="$attrs"
        v-if="type == 'primary'"
        :label="label"
        :pt:root="`primary-btn-root ${attrs['pt:label']}`"
        :pt:label="`primary-btn-label ${attrs['pt:label']}`"
    />
    <PrimeButton
        v-bind="$attrs"
        v-if="type == 'secondary'"
        :label="label"
        :pt:root="`secondary-btn-root ${attrs['pt:label']}`"
        :pt:label="`secondary-btn-label ${attrs['pt:label']}`"
    />
    <PrimeButton
        v-bind="$attrs"
        v-if="type == 'tertiary'"
        :label="label"
        :pt:root="`tertiary-btn-root ${attrs['pt:label']}`"
        pt:label="`tertiary-btn-label ${attrs['pt:label']}`"
    />
</template>

<script setup>
defineProps({
    type: String,
    label: String,
});

//attributes passed to the component
const attrs = useAttrs();
</script>

<style lang="scss">
.primary-btn-root {
    @apply bg-blue-700 rounded w-full h-10;

    &:hover {
        background: linear-gradient(0deg, var(--slate-300, rgba(2, 6, 23, 0.16)) 0%, var(--slate-300, rgba(2, 6, 23, 0.16)) 100%),
            var(--blue-700, #006df5);
    }
}

.secondary-btn-root {
    @apply bg-blue-200 border-[1px] border-blue-700 w-full h-10 rounded-[4px] px-4;

    &:hover {
        //change label color on group hover
        [data-pc-section='label'] {
            color: theme('colors.white-700') !important;
        }

        @apply bg-blue-700;
    }
}

.tertiary-btn-root {
    @apply border-none rounded-[4px] w-full h-10;

    &:hover {
        @apply bg-blue-700;
    }
}

.primary-btn-label {
    @apply text-white-700 font-inter text-sm font-semibold;
}

.secondary-btn-label {
    @apply text-white-700 font-inter text-sm font-semibold;
}

.tertiary-btn-label {
    @apply text-white-700 font-inter text-sm font-semibold;
}
</style>
