import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);
am4core.addLicense('CH271623187');

export default defineNuxtPlugin(() => {
    return {
        provide: {
            am4core,
            am4charts,
        },
    };
});
