import type { RouterConfig } from '@nuxt/schema';

export default <RouterConfig>{
    routes: (_routes) => {
        let routesDirectory: any = null;
        const { ssrContext } = useNuxtApp();

        // check for subdomain in the url on the server
        if (process.server && ssrContext && ssrContext.event.node.req) {
            const req = ssrContext.event.node.req;
            let subdomain = req.headers['x-host'];

            if (!subdomain) {
                subdomain = req.headers.host?.split('.')[0];
            }

            if (subdomain === 'hive-trust' || subdomain === 'hive-trust-dev') {
                routesDirectory = 'trust';
            } else {
                routesDirectory = 'main';
            }
        }

        // check for subdomain in the url on the client
        if (process.client && window.location.hostname) {
            const subdomain = window.location.hostname.split('.')[0];
            if (subdomain === 'hive-trust' || subdomain === 'hive-trust-dev') {
                routesDirectory = 'trust';
            } else {
                routesDirectory = 'main';
            }
        }

        // function to clear a directory not in use
        function isUnderDirectory(route: any, directory: any) {
            const path = route.path;
            return path === '/' + directory || path.startsWith('/' + directory + '/');
        }

        let newRoutes = _routes;

        if (routesDirectory) {
            // filter routes
            newRoutes = _routes
                .filter((route: any) => {
                    const toRemove = routesDirectory === 'trust' ? 'main' : 'trust';
                    return !isUnderDirectory(route, toRemove);
                })
                .map((route: any) => {
                    if (isUnderDirectory(route, 'trust')) {
                        return {
                            ...route,
                            path: route.path.replace('/trust', ''),
                            name: route.name || 'index',
                        };
                    } else if (isUnderDirectory(route, 'main')) {
                        return {
                            ...route,
                            path: route.path.substr(routesDirectory.length + 1) || '/',
                            name: route.name || 'index',
                        };
                    }
                });

            //remove undefined items from newRoutes
            newRoutes = newRoutes.filter((route: any) => route);

            return newRoutes;
        }
    },
};
