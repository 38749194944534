export default {
	root: '',
	columntitle: {
		class: ['datatable-column-title'],
	},
	columnheadercontent: {
		class: ['datatable-column-header-content'],
	},
	bodycell: {
		class: ['datatable-body-cell'],
	},
};