<template>
    <NuxtLayout>
        <div class="error-page-container">
            <div class="relative">
                <span class="text-error-code-lg">{{ props.error?.statusCode }}</span>
                <img
                    src="@/assets/orwell/not-feeling-good.png"
                    alt="privacy bee orwell"
                    class="orwell-illustration"
                />
            </div>

            <h1 v-if="props.error?.statusCode == 404">Page Not Found</h1>
            <h1 v-else-if="props.error?.statusCode == 500">Oops, Something Went Wrong!</h1>
            <h1 v-else>Unrecognized Error</h1>

            <p v-if="devEnv" class="text-slate-500 max-w-[400px] text-center">
                {{ props.error?.message }}
            </p>
            <p v-else-if="props.error?.statusCode == 404">
                It looks like you've wandered into an empty hive. The page you're looking for isn't
                here!
            </p>
            <p v-else>
                It appears that our bees have started nesting in our servers! We'll have everything
                back online shortly.
            </p>

            <div class="flex gap-3 w-[300px] sm:w-[400px]">
                <PbButton
                    type="primary"
                    label="Back to Dashboard"
                    @click="usePbRouter('/personal/dashboard')"
                />
                <PbButton
                    type="secondary"
                    label="Contact Support"
                    class="group"
                    pt:label="!text-blue-700 group-hover:!text-white-700"
                    @click="usePbRouter('/personal/support')"
                />
            </div>

            <div v-if="devEnv">
                <div
                    v-if="props.error?.stack"
                    v-html="props.error?.stack"
                    class="stack-container"
                ></div>
            </div>
        </div>
    </NuxtLayout>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app';
import PbButton from '@/components/shared/PbButton.vue';

const props = defineProps({
    error: Object as () => NuxtError,
});

//check if the environment is development to hide descriptive error messages
const devEnv = process.env.NODE_ENV === 'development';
</script>

<style lang="scss" scoped>
.orwell-illustration {
    @apply absolute top-[34%] left-[24%] size-40 sm:size-56 sm:top-[36%] sm:left-[26%];
}

.error-page-container {
    min-height: calc(100vh - var(--size-header-height) - var(--size-footer-height));
    @apply flex flex-col justify-center items-center bg-blue-100 gap-4;
}

.stack-container {
    @apply bg-slate-700 text-white-700 p-2 rounded-lg text-xs max-w-6xl overflow-auto;
}
</style>
